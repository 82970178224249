import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useEffect, useState } from "react";
import { MonitoringRow } from "./MonitoringRow";
import EnvironmentalImpact from "./EnvironmentalImpact";
import YearlyBar from "./YearlyBar";
import { IoArrowBackCircle } from "react-icons/io5";
import DraggableHeaders from "./DraggableHeaders";
import TicketsModal from "./TicketsModal";
import { BsSearch } from "react-icons/bs";
import { GraphModal } from "./GraphModal";
import Trailing13MonthsBar from "./Trailing13MonthsBar";
import { PaginationNav1Presentation } from "../Utility/Pagination";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import AlertIconList from "./Alerts/AlertIconList";

const getHeaders = () => [
	{ id: "title", content: "Client Name - Site Name" },
	{ id: "alerts", content: "Alerts" },
	{ id: "capacity", content: "DC Capacity" },
	{ id: "energy-yesterday", content: "Energy Yesterday" },
	{ id: "energy-lifetime", content: "Energy Lifetime" },
	{ id: "trailing-7", content: "Trailing 7 Days Generation" },
	{ id: "trailing-30", content: "Trailing 30 Days Generation" },
	{ id: "trailing-12", content: "Trailing 12 Months Generation" },
];

export default function MonitoringDashboard({ sidebarOpen, api, token, isCommercial }) {
	const navigate = useNavigate();
	const { user } = useAuth0();
	const [fetchingData, setFetchingData] = useState(true);
	const [sites, setSites] = useState([]);
	const [energyLifetime, setEnergyLifetime] = useState(0);
	const [aggregatedParents, setAggregatedParents] = useState([]);
	const [totalInstalledKw, setTotalInstalledKw] = useState(0);
	const [powerSavingValue, setPowerSavingValue] = useState(0);
	const [headers, setHeaders] = useState(getHeaders());
	const [sortedSites, setSortedSites] = useState([]);
	const [allTickets, setAllTickets] = useState([]);
	const [showTicketsModal, setShowTicketsModal] = useState(false);
	const [showRotateModal, setShowRotateModal] = useState(false);
	const [filterText, setFilterText] = useState("");
	const [sortByHeader, setSortByHeader] = useState("none"); // none or titleId + ascending or descending
	const [contractorFilter, setContractorFilter] = useState("all");
	const [contractorOptions, setContractorOptions] = useState(["all"]);
	const [filteredTickets, setFilteredTickets] = useState([]);
	const [filteredSites, setFilteredSites] = useState([]);
	const [monitoringSubscription, setMonitoringSubscription] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(-1);
	const [sitesOnPage, setSitesOnPage] = useState([]);
	const [unEnteredFilterText, setUnEnteredFilterText] = useState("");
	const SITES_PER_PAGE = 10;

	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};

	useEffect(() => {
		if (api === "" || !token || isCommercial) {
			return;
		}

		try {
			fetch(api + "/installation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					userEmail: user.email,
				}),
				mode: "cors",
			})
				.then((res) => {
					return res.json();
				})
				.then((res) => {
					if (!res.monitoringSubscription) {
						navigate("/installation");
					}
					setMonitoringSubscription(res.monitoringSubscription);
				});
		} catch (error) {}
	}, [api, token, user.email, navigate, isCommercial]);

	useEffect(() => {
		if (!user || !api || !token) {
			return;
		}
		setFetchingData(true);
		fetch(api + "/monitoring/all", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({ email: user.email }),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					setSites(jsonData.sites);
					setContractorOptions(jsonData.contractorOptions);
					setAllTickets(jsonData.allTickets);
					setTotalPages(Math.ceil(jsonData.sites.length / SITES_PER_PAGE));
				}
			});
	}, [api, token, user]);

	useEffect(() => {
		// Step 1: Filter by contractor and site text
		let processedSites = [...sites];
		if (contractorFilter === "no contractor set") {
			processedSites = processedSites.filter((site) => !site.x_studio_installer);
		} else if (contractorFilter !== "all") {
			processedSites = processedSites.filter((site) => site.x_studio_installer === contractorFilter);
		}
		if (filterText !== "") {
			processedSites = processedSites.filter((site) => removeAutonums(site.name).toLowerCase().includes(filterText.toLowerCase()));
		}
		setFilteredSites(processedSites);

		// Step 2: Sort
		switch (sortByHeader) {
			case "title-desc":
				processedSites.sort((a, b) => b.name.localeCompare(a.name));
				break;
			case "title-asc":
				processedSites.sort((a, b) => a.name.localeCompare(b.name));
				break;
			case "energy-lifetime-desc":
				processedSites.sort((a, b) => b.energyLifetime - a.energyLifetime);
				break;
			case "energy-lifetime-asc":
				processedSites.sort((a, b) => a.energyLifetime - b.energyLifetime);
				break;
			case "capacity-desc":
				processedSites.sort((a, b) => b.totalDCCapacity - a.totalDCCapacity);
				break;
			case "capacity-asc":
				processedSites.sort((a, b) => a.totalDCCapacity - b.totalDCCapacity);
				break;
			case "trailing-7-desc":
				processedSites.sort((a, b) => b.trailing7DaysPercent - a.trailing7DaysPercent);
				break;
			case "trailing-7-asc":
				processedSites.sort((a, b) => a.trailing7DaysPercent - b.trailing7DaysPercent);
				break;
			case "trailing-30-desc":
				processedSites.sort((a, b) => b.trailing30DaysPercent - a.trailing30DaysPercent);
				break;
			case "trailing-30-asc":
				processedSites.sort((a, b) => a.trailing30DaysPercent - b.trailing30DaysPercent);
				break;
			case "trailing-12-desc":
				processedSites.sort((a, b) => b.trailing12Percent - a.trailing12Percent);
				break;
			case "trailing-12-asc":
				processedSites.sort((a, b) => a.trailing12Percent - b.trailing12Percent);
				break;
			case "energy-yesterday-desc":
				processedSites.sort((a, b) => b.yesterdayEnergy - a.yesterdayEnergy);
				break;
			case "energy-yesterday-asc":
				processedSites.sort((a, b) => a.yesterdayEnergy - b.yesterdayEnergy);
				break;
			case "alerts-desc":
				processedSites.sort((a, b) => b.alerts.length - a.alerts.length);
				break;
			case "alerts-asc":
				processedSites.sort((a, b) => a.alerts.length - b.alerts.length);
				break;
			default:
				break;
		}
		// Update state
		setTotalPages(Math.ceil(processedSites.length / SITES_PER_PAGE));
		setSortedSites(processedSites);
	}, [contractorFilter, filterText, sites, sortByHeader]);

	useEffect(() => {
		if (sortedSites.length > 0) {
			setFetchingData(false);
		}
	}, [sortedSites]);

	useEffect(() => {
		let processedSites = [...sortedSites];
		const startIndex = (page - 1) * SITES_PER_PAGE;
		const endIndex = startIndex + SITES_PER_PAGE;
		processedSites = processedSites.slice(startIndex, endIndex);
		setSitesOnPage(processedSites);
	}, [page, sortedSites]);

	useEffect(() => {
		let parents = [...filteredSites];
		let measuredEnergy = 0;
		let parentMeasured = [];
		let parentExpected = [];
		let parentAsBuilt = [];
		let newTotalInstalledKw = 0;
		let newPowerSavingValue = 0;
		let hasAsBuilt = false;
		parents.forEach((parent) => {
			if (!hasAsBuilt) {
				if (parent.hasAsBuilt === true) {
					hasAsBuilt = true;
				}
			}
			let powerSavingFactor = 0;
			if (parent.name.startsWith("PS 25777")) {
				powerSavingFactor = 0.224;
			} else if (parent.name.startsWith("PS 26910")) {
				powerSavingFactor = 0.155;
			} else if (parent.name.startsWith("PS 21010")) {
				powerSavingFactor = 0.144;
			} else if (parent.name.startsWith("PS 23610")) {
				powerSavingFactor = 0.235;
			}
			parent.children.forEach((child) => {
				if (child.x_studio_meter_kw) {
					newTotalInstalledKw += child.x_studio_meter_kw;
				}
				child.monitoringData.forEach((data) => {
					measuredEnergy += Math.ceil(data.kwh_measured);
					if (powerSavingFactor > 0) {
						newPowerSavingValue += Math.ceil(data.kwh_measured) * powerSavingFactor;
					}
					parentMeasured.push({
						Date: data.ts,
						kWh:
							child.x_studio_monitor_multiplier > 0
								? Math.ceil(data.kwh_measured) * child.x_studio_monitor_multiplier
								: Math.ceil(data.kwh_measured),
					});

					parentExpected.push({
						Date: data.ts,
						kWh: Math.floor(data.kwh_expected),
					});

					parentAsBuilt.push({
						Date: data.ts,
						kWh: Math.floor(data.kwh_as_built),
					});
				});
			});
		});

		const newMeasuredEnergy = parentMeasured.reduce((acc, obj) => acc + obj.kWh, 0);

		parentMeasured.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		parentExpected.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		parentAsBuilt.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		setPowerSavingValue(newPowerSavingValue);
		let parentFormatted = {
			measuredData: parentMeasured,
			expectedData: parentExpected,
			asBuiltData: parentAsBuilt,
			hasAsBuilt: hasAsBuilt,
		};
		setTotalInstalledKw(newTotalInstalledKw);
		setAggregatedParents(parentFormatted);
		setEnergyLifetime(newMeasuredEnergy);
	}, [filteredSites]);

	useEffect(() => {
		let unfilteredTickets = [...allTickets];
		let installationIds = [];

		for (let i = 0; i < sortedSites.length; i++) {
			installationIds.push(sortedSites[i].id);
			for (let j = 0; j < sortedSites[i].children.length; j++) {
				installationIds.push(sortedSites[i].children[j].id);
			}
		}
		unfilteredTickets = unfilteredTickets.filter((filterData) => installationIds.includes(filterData.x_studio_installation[0]));
		setFilteredTickets(unfilteredTickets);
	}, [sortedSites, allTickets]);

	const changeContractFilter = (e) => {
		setContractorFilter(e.target.value);
	};

	return (
		<>
			<div
				className={`${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: isCommercial
						? "bg-CommercialBackgroundImage"
						: "bg-1stleBackgroundImage"
				} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}
			/>
			{showTicketsModal ? <TicketsModal allTickets={filteredTickets} user={user} showTitle={true} setShowTicketsModal={setShowTicketsModal} /> : null}

			<div className={`${sidebarOpen ? "md:ml-64" : "md:ml-10"} z-[100] `}>
				<div className="text-sm text-start text-gray-800 m-10 overflow-hidden  ">
					<div className="flex justify-center items-center pb-10">
						{user.email === "ps@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_PUBLIC_STORAGE_LOGO_URL} alt="PUBLIC STORAGE" />
						) : user.email === "ess@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_EXTRA_SPACE_STORAGE_LOGO_URL} alt="EXTRA SPACE STORAGE" />
						) : null}
					</div>

					{fetchingData ? (
						<div className="mt-4 p-4 bg-white flex justify-center items-center">
							<div className="  flex justify-center items-center flex-col p-5 ">
								<img src={process.env.REACT_APP_LOADING_SVG} alt="Loading..." />
								<p className="mt-2 font-bold">Loading Your Data...</p>
							</div>
						</div>
					) : isCommercial ? (
						<div className="bg-slate-50 bg-opacity-95">
							<div className="mt-4 p-4  bg-opacity-95 rounded-lg">
								<div className="flex justify-between items-center mb-4">
									<div className="w-full" />
									<div className="text-center w-full">
										<p className="text-2xl md:text-3xl font-bold ">Fleet</p>
									</div>
									<div className="flex flex-col items-end justify-end w-full">
										<select value={contractorFilter} onChange={changeContractFilter} className="p-1 border border-black">
											{contractorOptions.map((option, index) => (
												<option key={index} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="gap-4 h-full md:flex">
									<div className="w-full flex flex-col border border-black  rounded-lg bg-black overflow-hidden mb-4 md:mb-0">
										<p className="text-lg text-white p-2 font-bold text-center">General Information</p>
										<div className="p-4 flex flex-col gap-2 bg-white h-full">
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Total kW Installed</p>
												<p>{Number(totalInstalledKw.toFixed(2)).toLocaleString("en-US") + " "} kW</p>
											</div>
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Total kWhs Produced</p>
												<p>{Number(energyLifetime).toLocaleString("en-US") + " "}kWh</p>
											</div>
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Number of Sites</p>
												<p>{Number(sortedSites.length).toLocaleString("en-US")}</p>
											</div>
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Number of Tickets</p>

												<p
													onClick={() => setShowTicketsModal(true)}
													className="cursor-pointer text-blue-500 hover:text-blue-700 underline">
													{filteredTickets ? Number(filteredTickets.length).toLocaleString("en-US") : 0}
												</p>
											</div>
										</div>
									</div>

									<EnvironmentalImpact generatedToDate={energyLifetime} />
								</div>
								<div
									className={`p-3 mt-4 sm:hidden flex items-center justify-center w-auto cursor-pointer ${
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com"
											? "bg-lime-400 hover:bg-lime-500"
											: isCommercial
											? "bg-[#FFEA00] hover:bg-yellow-400"
											: "bg-blue-500 hover:bg-blue-600"
									}`}
									onClick={() => setShowRotateModal(true)}>
									<p className="font-bold cursor-pointer">View Graphs</p>
								</div>
								{aggregatedParents && aggregatedParents.measuredData && aggregatedParents.measuredData.length > 0 ? (
									<div className="text-color-black w-full hidden sm:block mt-0 sm:mt-4">
										<Trailing13MonthsBar
											measuredData={aggregatedParents.measuredData}
											expectedData={aggregatedParents.expectedData}
											asBuiltData={aggregatedParents.asBuiltData}
											expandedByDefault={true}
											allowResize={false}
											hasAsBuilt={aggregatedParents.hasAsBuilt}
										/>
									</div>
								) : null}
								{showRotateModal ? <GraphModal setShowRotateModal={setShowRotateModal} /> : null}
							</div>

							<div className="mt-4 px-4 pb-2 bg-opacity-95 rounded-lg">
								<p className="text-2xl md:text-3xl font-bold mb-4">Sites</p>

								<form
									onSubmit={(e) => {
										e.preventDefault();
										setFilterText(unEnteredFilterText);
										setPage(1);
									}}
									className="flex w-full justify-center items-center mb-2 border border-black rounded-lg">
									<input
										type="text"
										className="p-2 w-full rounded-l-lg"
										placeholder="Search for sites"
										value={unEnteredFilterText}
										onChange={(e) => setUnEnteredFilterText(e.target.value)}
									/>
									<button
										type="button"
										onClick={(e) => {
											e.preventDefault();
											setFilterText(unEnteredFilterText);
											setPage(1);
										}}
										className="border rounded-r-lg border-l-black p-3 bg-white">
										<BsSearch />
									</button>
								</form>
								<div className="w-full grid grid-cols-1 laptop:grid-cols-9">
									<div className="col-span-9 border-b border-black hidden laptop:block">
										<DraggableHeaders items={headers} setItems={setHeaders} setSortByHeader={setSortByHeader} sortByHeader={sortByHeader} />
									</div>
									{fetchingData
										? null
										: sitesOnPage && sitesOnPage.length > 0
										? sitesOnPage.map((mapData, index) => {
												return (
													<div
														key={mapData.id}
														className={`${
															index === SITES_PER_PAGE - 1
																? "rounded-b-lg"
																: index === 0
																? "rounded-t-lg laptop:rounded-t-none"
																: ""
														} border-l border-r border-t border-b border-black grid grid-cols-1 laptop:grid-cols-9 col-span-1 laptop:col-span-9`}>
														<MonitoringRow
															site={mapData}
															bgColor={index % 2 === 0 ? "bg-white" : "bg-gray-200"}
															headersOrder={headers}
															isLast={SITES_PER_PAGE - 1 === index ? true : false}
														/>
													</div>
												);
										  })
										: null}
								</div>
								<div className="flex justify-center mt-1 ">
									<div className="flex justify-center items-enter gap-2  p-2">
										<div>
											<button
												onClick={() => setPage(1)}
												className={`flex border border-black cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page > 1 ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page > 1 ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page <= 1}>
												<FaChevronLeft size="0.6rem" />
												<FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
											</button>
										</div>
										<div>
											<button
												onClick={() => setPage((prev) => prev - 1)}
												className={`flex  border border-black  cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page > 1 ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page > 1 ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page <= 1}>
												<FaChevronLeft size="0.6rem" />
											</button>
										</div>
										<div className="flex border border-black justify-center items-center bg-white p-2 rounded-lg">
											<p>{`${page} / ${totalPages}`}</p>
										</div>

										<div>
											<button
												onClick={() => setPage((prev) => prev + 1)}
												className={`flex border border-black cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page < totalPages ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page < totalPages ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page >= totalPages}>
												<FaChevronRight size="0.6rem" />
											</button>
										</div>
										<div>
											<button
												onClick={() => setPage(totalPages)}
												className={`flex border border-black cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page < totalPages ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page < totalPages ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page >= totalPages}>
												<FaChevronRight size="0.6rem" />
												<FaChevronRight size="0.6rem" className="-translate-x-1/2" />
											</button>
										</div>
									</div>
								</div>
								<p className="mt-2 text-center text-xs text-underline">Data updates occur daily at 2 AM PT, adding the previous day's data</p>
							</div>
						</div>
					) : monitoringSubscription && sites.length > 0 ? (
						navigate("/monitoring/" + sites[0].id)
					) : null}
				</div>
			</div>
		</>
	);
}
