import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

export default function DraggableHeaders({ items, setItems, setSortByHeader, sortByHeader }) {
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const reorderedItems = Array.from(items);
		const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
		reorderedItems.splice(result.destination.index, 0, reorderedItem);

		setItems(reorderedItems);
	};

	return (
		<div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable-horizontal" direction="horizontal">
					{(provided, snapshot) => {
						return (
							<div ref={provided.innerRef} {...provided.droppableProps} style={{ display: "flex" }} className="w-full bg-black rounded-t-lg">
								{items.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												className={
													item.id !== "title"
														? "p-2  text-white basis-[11.1111111%] w-full flex items-center rounded-lg"
														: "p-2  text-white basis-[22.222222%] flex items-center rounded-lg"
												}
												style={{
													userSelect: "none",
													backgroundColor: snapshot.isDragging ? "rgb(100,116,139)" : "rgb(0,0,0)",

													color: "white",
													...provided.draggableProps.style,
												}}>
												<p>{item.content}</p>
												<div className="flex items-center justify-center">
													{sortByHeader === item.id + "-desc" ? (
														<button onClick={() => setSortByHeader(item.id + "-asc")}>
															<FaCaretUp size={20} color={sortByHeader === item.id + "-asc" ? "red" : "white"} />
														</button>
													) : (
														<button onClick={() => setSortByHeader(item.id + "-desc")}>
															<FaCaretDown size={20} color={sortByHeader === item.id + "-desc" ? "red" : "white"} />
														</button>
													)}
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</div>
	);
}
