import React, { useRef } from "react";

export const About1LE = ({ sidebarOpen}) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    return (
        <div className="flex items-center justify-center bg-1stleBackgroundImage fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto">
            <div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
                {windowSize.current[0] > 768 ? (
                    <iframe
                        width="853"
                        height="480"
                        src={process.env.REACT_APP_1STLE_VIDEO_URL}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                ) : (
                    <iframe 
                        width="300"
                        height="300"
                        src={process.env.REACT_APP_1STLE_VIDEO_URL}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                )}
            </div>
        </div>
    )
}