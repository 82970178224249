import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

export const SubmitTicket = ({ api, sidebarOpen, token, isCommercial }) => {
	const { user } = useAuth0();
	const params = useParams();
	const [systemInstalled, setSystemInstalled] = useState();
	const [service, setService] = useState();
	const [inverterIssue, setInverterIssue] = useState();
	const [interiorDamage, setInteriorDamage] = useState();
	const [name, setName] = useState("");
	const [installation, setInstallation] = useState("");
	const [address, setAddress] = useState("");
	const [issue, setIssue] = useState("");
	const [subIssue, setSubIssue] = useState("");
	const [description, setDescription] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (!token || api === "" || isCommercial) {
			return;
		}
		try {
			fetch(api + "/installation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					userEmail: user.email,
				}),
				mode: "cors",
			})
				.then((res) => {
					return res.json();
				})
				.then((res) => {
					setName(res.name);
					setInstallation(res.parent);
					setAddress(res.street);
				});
		} catch (error) {}
	}, [api, token, user.email, isCommercial]);

	useEffect(() => {
		if (!token || api === "" || !isCommercial) {
			return;
		}

		fetch(api + "/installation/" + params.installationId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			mode: "cors",
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				setName(res.name);
				setInstallation(res.id);
				setAddress(res.street);
			})
			.catch((error) => {});
	}, [api, token, params.installationId, user.email, isCommercial]);

	const submitTicket = () => {
		alert("Thank you. Your case has been submitted!");
		try {
			fetch(api + "/tickets/createticket", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					name: !isCommercial ? installation[1] : name,
					installation: !isCommercial ? installation[0] : installation,
					email: user.email,
					address: address,
					issue: systemInstalled === true ? issue : "Pre-Installation Issue",
					subIssue: subIssue,
					description: getDescription(description),
				}),
			}).then((response) => response.json());
		} catch (error) {}
	};

	const getDescription = (description) => {
		if (inverterIssue === true) {
			return description + " - Inverter Screen Present";
		} else if (inverterIssue === false) {
			return description + " - Inverter Screen Not Present";
		} else if (interiorDamage === true) {
			return description + " - Interior Damage";
		} else if (interiorDamage === false) {
			return description + " - No Interior Damage";
		} else {
			return description;
		}
	};

	if (isCommercial) {
		return (
			<div
				className={`overflow-auto fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover ${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com" 
							? "bg-ESSBackgroundImage"
							: "bg-CommercialBackgroundImage"
				}`}>
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center">
						<form
							onSubmit={submitTicket}
							className="bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
							<div className="flex flex-col items-center justify-center mb-14">
								<div
									className={`absolute top-5 left-5 p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com"
												? "bg-lime-400 hover:bg-lime-500"
												: "bg-[#FFEA00] hover:bg-yellow-400"
									}`}
									onClick={() => navigate(-1)}>
									<IoArrowBackCircle className="text-2xl cursor-pointer" />
									<p className="cursor-pointer">Go back</p>
								</div>
							</div>
							<div className="flex items-center justify-center mb-10">
								<span className="text-2xl md:text-5xl font-bold">Submit a Ticket</span>
							</div>
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="systemInstalled">
										Has your system been installed?
									</label>
									<div className="radio text-black">
										<label>
											<input 
												className="mr-2" 
												type="radio" 
												name="system" 
												value="Yes" 
												onChange={() => setSystemInstalled(true)}
											/>
											Yes
										</label>
									</div>
									<div className="radio text-black">
										<label>
											<input
												className="mr-2"
												type="radio"
												name="system"
												value="No"
												onChange={() => {
													setSystemInstalled(false);
													alert("Service Tickets are creatable once the installation is completed.");
												}}
											/>
											No
										</label>
									</div>
								</div>
							</div>
							<div className={systemInstalled === true ? "flex flex-wrap -mx-3 mb-6" : "hidden"}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="address">
										Which installation has an issue?
									</label>
									<select
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="address"
										defaultValue={"Default"}
										required>
										<option value="Default">{name}</option>
									</select>
								</div>
							</div>
							<div className={systemInstalled === true ? "flex flex-wrap -mx-3 mb-6" : "hidden"}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="message">
										Describe the issue or concern you are having
									</label>
									<textarea
										className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
										id="message"
										placeholder="Your message or question"
										onChange={(e) => setDescription(e.target.value)}
										required></textarea>
								</div>
							</div>
							<div className={systemInstalled === true ? "flex justify-center items-center flex-wrap -mx-3 mb-6" : "hidden"}>
								<div className="md:w-1/3">
									<button
										className={`inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-black rounded focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 ${
											user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com"
												? "bg-lime-400 hover:bg-lime-500"
												: "bg-[#FFEA00] hover:bg-yellow-400"
										}`}
										type="submit">
										Submit Ticket
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div
				className="bg-1stleBackgroundImage overflow-auto fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover">
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center">
						<form
							onSubmit={submitTicket}
							className="bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full sm:w-[1200px] bg-opacity-95">
							<div className="flex items-center justify-center mb-10">
								<span className="text-2xl md:text-5xl font-bold">Submit a Ticket</span>
							</div>
							<div className="flex flex-wrap -mx-3 mb-6">
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="systemInstalled">
										Has your system been installed?
									</label>
									<div className="radio text-black">
										<label>
											<input className="mr-2" type="radio" name="system" value="Yes" onChange={() => setSystemInstalled(true)} />
											Yes
										</label>
									</div>
									<div className="radio text-black">
										<label>
											<input className="mr-2" type="radio" name="system" value="No" onChange={() => setSystemInstalled(false)} />
											No
										</label>
									</div>
								</div>
							</div>
							<div className={systemInstalled === true ? "flex flex-wrap -mx-3 mb-6" : "hidden"}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="service">
										How can we help you?
									</label>
									<div className="radio text-black">
										<label>
											<input className="mr-2" type="radio" name="service" value="issue" onChange={() => setService(true)} />
											There is an issue with my system
										</label>
									</div>
									<div className="radio text-black">
										<label>
											<input className="mr-2" type="radio" name="service" value="no issue" onChange={() => setService(false)} />
											I'm looking to schedule additional service for my solar system
										</label>
									</div>
								</div>
							</div>
							<div
								className={
									systemInstalled === false || (systemInstalled === true && service === true) ? "flex flex-wrap -mx-3 mb-6" : "hidden"
								}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="address">
										{systemInstalled === false
											? "At which address is your system going to be installed?"
											: "Which installation has an issue?"}
									</label>
									<select
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="address"
										defaultValue={"Default"}
										required>
										<option value="Default" disabled>
											-- Select an Installation --
										</option>
										<option value={address}>{address}</option>
									</select>
								</div>
							</div>
							<div className={systemInstalled === true && service === false ? "flex justify-center items-center flex-wrap -mx-3 mb-6" : "hidden"}>
								<div>
									<button
										className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-blue-600"
										type="button"
										onClick={() => navigate("/services")}>
										Check out our Services
									</button>
								</div>
							</div>
							<div
								className={
									systemInstalled === false || (systemInstalled === true && service === true) ? "flex flex-wrap -mx-3 mb-6" : "hidden"
								}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="issue">
										What is your issue or concern about?
									</label>
									{systemInstalled === false ? (
										<select
											className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="subIssue"
											defaultValue={"Default"}
											onChange={(e) => setSubIssue(e.target.value)}
											required>
											<option value="Default" disabled>
												-- Select an Option --
											</option>
											<option value="Tax Question">Tax Questions</option>
											<option value="Status of Construction">Status of Construction</option>
											<option value="Request copies of">Request copies of contract documents</option>
											<option value="Request contract from">Request contract from Sales Representative</option>
											<option value="Status of Reimbursement">Status of Reimbursement</option>
											<option value="Cancellation">Cancellation</option>
											<option value="Other">Other</option>
										</select>
									) : (
										<select
											className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="issue"
											defaultValue={"Default"}
											onChange={(e) => setIssue(e.target.value)}
											required>
											<option value="Default" disabled>
												-- Select an Option --
											</option>
											<option value="Other">Other</option>
											<option value="Electrical Issue">Electrical Issue</option>
											<option value="Inverter Issue">Inverter Issue</option>
											<option value="Meter Issue">Meter Issue</option>
											<option value="Panel Issue">Panel Issue</option>
											<option value="System Check">System Check</option>
											<option value="Pest Control">Pest Control</option>
											<option value="Roof Leak">Roof Leak</option>
											<option value="High Utility Bill">High Utility Bill</option>
										</select>
									)}
								</div>
							</div>
							<div
								className={systemInstalled === true && issue === "Inverter Issue" && service === true ? "flex flex-wrap -mx-3 mb-6" : "hidden"}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="inverterIssue">
										Does your inverter have a display screen?
									</label>
									<div className="radio text-black">
										<label>
											<input
												className="mr-2"
												type="radio"
												name="inverter"
												value="Inverter Screen Present"
												onChange={() => setInverterIssue(true)}
											/>
											Yes
										</label>
									</div>
									<div className="radio text-black">
										<label>
											<input
												className="mr-2"
												type="radio"
												name="inverter"
												value="Inverter Screen Not Present"
												onChange={() => setInverterIssue(false)}
											/>
											No
										</label>
									</div>
								</div>
							</div>
							<div className={systemInstalled === true && issue === "Roof Leak" && service === true ? "flex flex-wrap -mx-3 mb-6" : "hidden"}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="interiorDamage">
										Is there interior damage?
									</label>
									<div className="radio text-black">
										<label>
											<input className="mr-2" type="radio" name="roof" value="Interior Damage" onChange={() => setInteriorDamage(true)} />
											Yes
										</label>
									</div>
									<div className="radio text-black">
										<label>
											<input
												className="mr-2"
												type="radio"
												name="roof"
												value="No Interior Damage"
												onChange={() => setInteriorDamage(false)}
											/>
											No
										</label>
									</div>
								</div>
							</div>
							<div
								className={
									(systemInstalled === false && subIssue !== "") || (systemInstalled === true && service === true && issue !== "")
										? "flex flex-wrap -mx-3 mb-6"
										: "hidden"
								}>
								<div className="w-full px-3">
									<label className="block uppercase tracking-wide text-black text-sm md:text-lg font-bold mb-2" htmlFor="message">
										Describe the issue or concern you are having
									</label>
									<textarea
										className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
										id="message"
										placeholder="Your message or question"
										onChange={(e) => setDescription(e.target.value)}
										required></textarea>
								</div>
							</div>
							<div
								className={
									(systemInstalled === false && subIssue !== "") || (systemInstalled === true && service === true && issue !== "")
										? "flex justify-center items-center flex-wrap -mx-3 mb-6"
										: "hidden"
								}>
								<div className="md:w-1/3">
									<button
										className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-blue-600"
										type="submit">
										Submit Ticket
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
};
