import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import LoadingCircle from "./Utility/LoadingCircle";

export const ViewInstallation = ({ api, sidebarOpen, token, isCommercial }) => {
	const params = useParams();
	const { user } = useAuth0();
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [systemPrice, setSystemPrice] = useState("");
	const [contractSignedDate, setContractSignedDate] = useState("");
	const [installDate, setInstallDate] = useState("");
	const [systemSize, setSystemSize] = useState("");
	const [ptoDate, setPtoDate] = useState("");
	const [finalContractCost, setFinalContractCost] = useState("");
	const [installStatus, setInstallStatus] = useState("");
	const [meters, setMeters] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [installationNotFound, setInstallationNotFound] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (api === "" || !token) {
			return;
		}

		setIsLoading(true);
		fetch(api + "/installation/" + params.installationId, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({ userEmail: user.email }),
			mode: "cors",
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				if (res.msg) {
					if (res.msg === "installation not found") {
						setInstallationNotFound(true);
					}
				} else {
					setIsLoading(false);
					setName(res.name);
					setPhone(res.phone);
					setStreet(res.street);
					setCity(res.city);
					setState(res.state);
					setZip(res.zip);
					setSystemPrice(res.systemPrice);
					setContractSignedDate(res.contractSignedDate);
					setInstallDate(res.installDate);
					setSystemSize(res.systemSize);
					setPtoDate(res.ptoDate);
					setFinalContractCost(res.finalContractCost);
					setInstallStatus(res.installStatus);
					setMeters(res.meters);
				}
			})
			.catch((error) => {});
	}, [api, params.installationId, token, user.email]);

	const currencyFormat = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const dateFormat = (inputDate) => {
		var date = new Date(inputDate);
		if (!isNaN(date.getTime())) {
			return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
		}
	};

	if (installationNotFound) {
		return (
			<>
				<div
					className={`${
						user.email === "ps@1stle.com"
							? "bg-PSBackgroundImage"
							: user.email === "ess@1stle.com"
							? "bg-ESSBackgroundImage"
							: isCommercial
							? "bg-CommercialBackgroundImage"
							: "bg-1stleBackgroundImage"
					} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}></div>

				{/* <div className=" bg-slate-200/[.29] fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover"></div> */}
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="m-10 p-4 bg-slate-50 bg-opacity-95">
						<div className="mt-4 flex flex-col items-center">
							<p className="text-2xl font-bold">404</p>
							<p className="text-xl mb-2">Installation not found</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	if (isCommercial) {
		return (
			<div
				className={`fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto ${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: "bg-CommercialBackgroundImage"
				}`}>
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-10 bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
							<div className="md:col-span-1 col-span-full">
								<div
									className={`absolute top-5 left-5 p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com"
											? "bg-lime-400 hover:bg-lime-500"
											: "bg-[#FFEA00] hover:bg-yellow-400"
									}`}
									onClick={() => navigate("/installation")}>
									<IoArrowBackCircle className="text-2xl cursor-pointer" />
									<p className="cursor-pointer">Go back</p>
								</div>
							</div>
							<div className="flex items-center justify-center col-span-full mt-5">
								<span className="font-bold text-2xl md:text-5xl">{name}</span>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full">
								<div
									className={`w-full p-1 mb-5 ${
										user.email === "ps@1stle.com" ? "bg-[#FFAC1C]" : user.email === "ess@1stle.com" ? "bg-lime-400" : "bg-[#FFEA00]"
									}`}>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-black">
										Customer Information
									</h1>
								</div>
								{isLoading ? (
									<div className="flex items-center justify-center">
										<LoadingCircle />
									</div>
								) : (
									<div>
										<p className="mb-3 font-normal text-gray-700">
											Address: {street && city && state && zip ? street + ", " + city + ", " + state + " " + zip : ""}
										</p>
									</div>
								)}
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full">
								<div
									className={`w-full p-1 mb-5 ${
										user.email === "ps@1stle.com" ? "bg-[#FFAC1C]" : user.email === "ess@1stle.com" ? "bg-lime-400" : "bg-[#FFEA00]"
									}`}>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-black">
										Site Info
									</h1>
								</div>
								{isLoading ? (
									<div className="flex items-center justify-center">
										<LoadingCircle />
									</div>
								) : (
									<div>
										<p className="mb-3 font-normal text-gray-700">PTO Date: {ptoDate}</p>
										<p className="mb-3 font-normal text-gray-700">
											Total System Cost: {systemPrice ? currencyFormat.format(systemPrice) : ""}
										</p>
										<p className="mb-3 font-normal text-gray-700">Contracted System Size: {systemSize + "kW"}</p>
										<p className="mb-3 font-normal text-gray-700">
											Contracted System Cost: {finalContractCost ? currencyFormat.format(finalContractCost) : ""}
										</p>
										<p className="mb-3 font-normal text-gray-700">Number of Meters: {meters ? meters : ""}</p>
										<p className="mb-3 font-normal text-gray-700">Install Status: {installStatus}</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="bg-1stleBackgroundImage fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto">
				<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
					<div className="flex items-center justify-center">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-10 bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
							<div className="flex items-center justify-center col-span-full">
								<span className="font-bold text-2xl md:text-5xl">{street}</span>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Customer Information
									</h1>
								</div>
								<div>
									<p className="mb-3 font-medium text-gray-700">{name.split("-")[0]}</p>
									<p className="mb-3 font-normal text-gray-700">Installation: {name}</p>
									<p className="mb-3 font-normal text-gray-700">Email: {user.email}</p>
									<p className="mb-3 font-normal text-gray-700">Phone: {phone}</p>
									<p className="mb-3 font-normal text-gray-700">Address: {street + " " + city + ", " + state + " " + zip}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Financing Details
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">System Pricing</p>
									<p className="mb-3 font-normal text-gray-700">System Price: {currencyFormat.format(systemPrice)}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Timeline
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">Contract Signed Date: {dateFormat(contractSignedDate)}</p>
									<p className="mb-3 font-normal text-gray-700">Installation Date: {dateFormat(installDate)}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										System Overview
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">System Size: {systemSize + "kW"}</p>
								</div>
							</div>
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Documents
									</h1>
								</div>
								<div>
									<p className="mb-3 font-normal text-gray-700">Design</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};
