import { useEffect, useState } from "react";
import MyChart from "./MyChart";
import MyChartCompare from "./MyChartCompare";
import GraphContainer from "./GraphContainer";

export default function WeeklyBar({ measuredData, expectedData, asBuiltData, removeGraph, expandedByDefault, hasAsBuilt = false }) {
	const [selectedWeekSpan, setSelectedWeekSpan] = useState("");
	const [weekSpanOptions, setWeekSpanOptions] = useState([]);
	const [measuredFiltered, setMeasuredFiltered] = useState([]);
	const [expectedFiltered, setExpectedFiltered] = useState([]);
	const [asBuiltFiltered, setAsBuiltFiltered] = useState([]);
	const [generatedTotal, setGeneratedTotal] = useState(0);
	const [selection, setSelection] = useState({
		measured: true,
		expected: true,
		asBuilt: false,
	});

	function getWeekSpans(data) {
		const weekSpans = new Set();

		data.forEach((item) => {
			const date = new Date(item.Date);
			const startOfWeek = new Date(date.setUTCDate(date.getUTCDate() - date.getUTCDay()));
			const endOfWeek = new Date(startOfWeek);
			endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 6);

			const span = `${startOfWeek.toISOString().split("T")[0]} - ${endOfWeek.toISOString().split("T")[0]}`;
			weekSpans.add(span);
		});

		return Array.from(weekSpans).sort();
	}

	useEffect(() => {
		const spans = getWeekSpans(measuredData);
		setWeekSpanOptions(spans);
		if (selectedWeekSpan === "") {
			setSelectedWeekSpan(spans[spans.length - 1]);
		}
	}, [measuredData, selectedWeekSpan]);

	useEffect(() => {
		if (!selectedWeekSpan) return;
		const [start, end] = selectedWeekSpan.split(" - ").map((d) => {
			const parts = d.split("-").map((part) => parseInt(part, 10));
			const date = new Date();
			date.setFullYear(parts[0], parts[1] - 1, parts[2]);
			return date;
		});
		start.setHours(0, 0, 0, 0);
		end.setHours(23, 59, 59, 999);

		const dateArray = [];
		let currentDate = new Date(start);
		while (currentDate <= end) {
			dateArray.push(new Date(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}

		const aggregatedExpected = {};
		const aggregatedMeasured = {};
		const aggregatedAsBuilt = {};

		dateArray.forEach((date) => {
			const key = date.toISOString().split("T")[0];
			aggregatedExpected[key] = 0;
			aggregatedMeasured[key] = 0;
			aggregatedAsBuilt[key] = 0;
		});

		expectedData.forEach((item) => {
			const dateKey = new Date(item.Date).toISOString().split("T")[0];
			if (dateKey in aggregatedExpected) {
				aggregatedExpected[dateKey] += item.kWh;
			}
		});

		measuredData.forEach((item) => {
			const dateKey = new Date(item.Date).toISOString().split("T")[0];
			if (dateKey in aggregatedMeasured) {
				aggregatedMeasured[dateKey] += item.kWh;
			}
		});

		asBuiltData.forEach((item) => {
			const dateKey = new Date(item.Date).toISOString().split("T")[0];
			if (dateKey in aggregatedAsBuilt) {
				aggregatedAsBuilt[dateKey] += item.kWh;
			}
		});

		const newExpectedFiltered = Object.keys(aggregatedExpected).map((key) => ({
			Date: key,
			kWh: aggregatedExpected[key],
		}));

		const newMeasuredFiltered = Object.keys(aggregatedMeasured).map((key) => ({
			Date: key,
			kWh: aggregatedMeasured[key],
		}));

		const newAsBuiltFiltered = Object.keys(aggregatedAsBuilt).map((key) => ({
			Date: key,
			kWh: aggregatedAsBuilt[key],
		}));

		setExpectedFiltered(newExpectedFiltered);
		setMeasuredFiltered(newMeasuredFiltered);
		setAsBuiltFiltered(newAsBuiltFiltered);
	}, [expectedData, measuredData, asBuiltData, selectedWeekSpan]);

	const handleWeekSpanChange = (event) => {
		setSelectedWeekSpan(event.target.value);
	};

	return (
		<GraphContainer
			title={`Daily ${generatedTotal ? "(" + generatedTotal + ")" : ""}`}
			removeGraph={removeGraph}
			selection={selection}
			setSelection={setSelection}
			expandedByDefault={expandedByDefault}
			hasAsBuilt={hasAsBuilt}>
			<div className="mb-4 flex gap-2 items-center">
				<label>Week: </label>
				<select value={selectedWeekSpan} onChange={handleWeekSpanChange} className="border border-black">
					<option value="">Select a week</option>
					{weekSpanOptions.map((span, index) => (
						<option key={index} value={span}>
							{span}
						</option>
					))}
				</select>
			</div>
			<MyChartCompare measuredData={measuredFiltered} expectedData={expectedFiltered} asBuiltData={asBuiltFiltered} period="day" selection={selection} />
		</GraphContainer>
	);
}
