import React from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export const Checkout = ({ api, token, service }) => {
	const [{ isPending }] = usePayPalScriptReducer();

	const createOrder = () => {
		return fetch(api + "/services/create-order", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				items: [
					{
						id: service === "panel-cleaning" ? 1 : 2,
						quantity: 1,
					},
				],
			}),
		})
			.then((res) => res.json())
			.then(({ id }) => {
				return id;
			});
	};

	const onApprove = (data, actions) => {
		return actions.order.capture().then((details) => {
			const firstName = details.payer.name.given_name;
			const lastName = details.payer.name.surname;
			const fullName = firstName + " " + lastName;
			const serviceName = details.purchase_units[0].items[0].name;
			alert(`Thank you ${firstName}. Your payment is being processed.`);

			fetch(api + "/services/approve-order", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,

				},
				body: JSON.stringify({
					name: fullName,
					service: serviceName,
				})
			})
				.then(response => {
					if (!response.ok) {
						throw new Error("Failed to approve payment");
					}
				})
				.catch(error => {})
		})
	};

	return (
		<div className="checkout">
			{isPending ? (
				<p>Loading</p>
			) : (
				<div className="mt-10">
					<PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={(data, actions) => onApprove(data, actions)} />
				</div>
			)}
		</div>
	);
};
