import React from "react";

export const LogoutButtonDialogBox = (props) => {
    const confirm = (e) => {
		e.preventDefault();
		props.confirmAction();
	};

	const cancel = (e) => {
		e.preventDefault();
		props.cancelAction();
	};

	return (
		<div className="fixed left-0 top-0 w-screen h-screen flex justify-center items-center bg-black/50 z-[1000]" onClick={(e) => cancel(e)}>
			<div className="bg-white m-2 rounded-lg w-auto" onClick={(e) => e.stopPropagation()}>
				<form className="p-10">
					<div className="flex flex-col gap-5 justify-center">
                        <span className="text-xl font-bold">Are you sure you want to log out?</span>
						<button type="button" onClick={(e) => confirm(e)} className="shadow rounded bg-lime-400 focus:shadow-outline focus:outline-none p-2 text-white text-lg">
							Confirm
						</button>
						<button type="button" onClick={(e) => cancel(e)} className="shadow rounded bg-gray-400 focus:shadow-outline focus:outline-none p-2 text-white text-lg">
							Cancel
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}