import { Link, useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";

export default function TicketsModal({ allTickets, setShowTicketsModal, user, projectId, showTitle = false, isCommercial }) {
	const [filterText, setFilterText] = useState("");
	const [filteredTickets, setFilteredTickets] = useState([]);
	const navigate = useNavigate();
	const handleCloseButton = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setShowTicketsModal(false);
	};

	const handleClickingInModal = (e) => {
		e.stopPropagation();
	};

	const handleClickingOutsideModal = (e) => {
		setShowTicketsModal(false);
	};

	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};

	useEffect(() => {
		if (filterText.trim() === "") {
			setFilteredTickets(allTickets);
		} else {
			let unfilteredTickets = [...allTickets];
			setFilteredTickets(unfilteredTickets.filter((filterData) => filterData.x_studio_installation[1].toLowerCase().includes(filterText.toLowerCase())));
		}
	}, [allTickets, filterText]);

	return (
		<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/[.75] z-[120] flex justify-center items-center" onClick={handleClickingOutsideModal}>
			<div className=" w-full m-10 md:w-2/5 rounded-lg" onClick={handleClickingInModal}>
				<div className={`p-4 flex justify-between items-center rounded-t-lg bg-gray-300`}>
					<div />
					<div>
						<p className="text-2xl font-bold text-black">
							Tickets{" "}
							{`(${
								allTickets
									? filteredTickets.length === allTickets.length
										? allTickets.length
										: `${filteredTickets.length}/${allTickets.length}`
									: 0
							})`}
						</p>
					</div>
					<div className="flex justify-center items-center">
						<button onClick={handleCloseButton}>
							<IoMdCloseCircle color={user.email === "ps@1stle.com" ? "bg-orange-500 " : "bg-green-500 "} size={30} />
						</button>
					</div>
				</div>
				<hr style={{ borderColor: "black" }} />
				{projectId ? null : (
					<>
						<form onSubmit={(e) => e.preventDefault()} className="flex">
							<input
								type="text"
								placeholder="Filter by site name"
								className="w-full p-2"
								value={filterText}
								onChange={(e) => setFilterText(e.target.value)}
							/>
							<div className="bg-white flex justify-center items-center p-3 border-l border-black">
								<FaSearch size={20} />
							</div>
						</form>
						<hr style={{ borderColor: "black" }} />
					</>
				)}
				<div className="flex flex-col max-h-[40vh] overflow-y-scroll bg-white">
					{filteredTickets && filteredTickets.length > 0 ? (
						filteredTickets.map((mapData, index) => {
							return (
								<div key={mapData.id}>
									<div className="p-4 w-full rounded-lg flex justify-between items-center">
										<div className="basis-2/3">
											{showTitle ? (
												<p className="font-bold text-lg md:text-xl">{removeAutonums(mapData.x_studio_installation[1])}</p>
											) : null}
											<p className="text-sm md:text-base">
												<span className="font-bold">Status:</span> {mapData.stage_id ? mapData.stage_id[1] : "-"}
											</p>
											<p className="text-sm md:text-base">
												<span className="font-bold">Category:</span> {mapData.x_studio_issue_type ? mapData.x_studio_issue_type : "-"}
											</p>
											<p className="text-sm md:text-base">
												<span className="font-bold">Description:</span>{" "}
												{mapData.x_studio_resolution ? mapData.x_studio_resolution : "-"}
											</p>
										</div>
										<div className="basis-1/3 flex justify-end">
											<Link
												to={`/tickets/${mapData.id}`}
												target="_blank"
												rel="noreferrer"
												className="text-sm md:text-base text-blue-500 underline hover:text-blue-700">
												View Ticket
											</Link>
										</div>
									</div>
									{index !== filteredTickets.length - 1 ? <hr className="border-b-1 border-black" /> : null}
								</div>
							);
						})
					) : (
						<p className="p-10 text-center font-bold text-lg">No tickets found</p>
					)}
				</div>
				{projectId ? (
					<div className="bg-gray-300 rounded-b-lg">
						<hr style={{ borderColor: "black" }} />
						<div className="flex gap-3 justify-center items-center p-3">
							<div
								className={` p-2 flex items-center justify-center gap-2 w-32 cursor-pointer ${
									user.email === "ps@1stle.com" ? "bg-orange-500 hover:bg-orange-600"
									: user.email === "ess@1stle.com" ? "bg-green-500 hover:bg-green-600"
									: isCommercial ? "bg-yellow-400 hover:bg-yellow-500"
									: "bg-blue-500 hover:bg-blue-600"
								}`}
								onClick={() => navigate("/submitticket/" + projectId, { state: { id: 1, name: projectId } })}>
								<p className="cursor-pointer text-black">Create Ticket</p>
							</div>
						</div>
					</div>
				) : (
					<div className="bg-gray-300 rounded-b-lg p-6 border-t border-black" />
				)}
			</div>
		</div>
	);
}
