import React from "react";

export const PageLoader = () => {

  return (
    <div className="h-screen w-screen m-auto flex items-center justify-center">
      <img src={process.env.REACT_APP_LOADING_SVG} alt="Loading..." />
    </div>

  );
};
