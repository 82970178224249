import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState, useSyncExternalStore } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ImArrowRight2, ImArrowLeft2 } from "react-icons/im";
import LoadingCircle from "../Utility/LoadingCircle";

export const ViewTicket = ({ api, sidebarOpen, token, isCommercial }) => {
	const { user } = useAuth0();
	const params = useParams();
	const navigate = useNavigate();
	const [status, setStatus] = useState("");
	const [category, setCategory] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [scheduledDate, setScheduledDate] = useState("");
	const [resolution, setResolution] = useState("");
	const [customerReferenceNumber, setCustomerReferenceNumber] = useState("");
	const [paymentBilledOn, setPaymentBilledOn] = useState("");
	const [createdDate, setCreatedDate] = useState("");
	const [installation, setInstallation] = useState("");
	const [ticketUpdates, setTicketUpdates] = useState("");
	const [customer, setCustomer] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [comment, setComment] = useState("");
	const [file, setFile] = useState(null);
	const [files, setFiles] = useState([]);
	const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/jpeg", "image/png", "image/gif"];
	const [installationId, setInstallationId] = useState(-1);

	const [tickets, setTickets] = useState([]);
	const [messages, setMessages] = useState([]);
	const [replies, setReplies] = useState([]);
	const [salesOrderTotal, setSalesOrderTotal] = useState(0);

	const allMessages = [...messages.map((message) => ({ ...message, type: "message" })), ...(replies || []).map((reply) => ({ ...reply, type: "reply" }))];
	const sortedMessages = allMessages.sort((a, b) => new Date(b.create_date) - new Date(a.create_date));

	const { ticketId } = params;
	const [currentIndex, setCurrentIndex] = useState();

	useEffect(() => {
		const initialIndex = tickets.findIndex((ticket) => ticket.id === parseInt(ticketId));
		setCurrentIndex(initialIndex >= 0 ? initialIndex : 0);
	}, [ticketId, tickets]);

	useEffect(() => {
		const controller = new AbortController();

		fetch(api + "/tickets", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				userEmail: user.email,
			}),
			mode: "cors",
			signal: controller.signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				setTickets(res.tickets);
				setInstallation(res.installation);
			})
			.catch((error) => {});

		return () => controller.abort();
	}, [api, token, user.email]);

	useEffect(() => {
		if (api === "") {
			return;
		}
		try {
			setIsLoading(true);
			fetch(api + "/tickets/" + params.ticketId, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				mode: "cors",
			})
				.then((res) => {
					return res.json();
				})
				.then((res) => {
					setIsLoading(false);
					setStatus(res.status);
					setCategory(res.category);
					setStreet(res.street);
					setCity(res.city);
					setState(res.state);
					setZip(res.zip);
					setScheduledDate(res.scheduledDate);
					setSalesOrderTotal(res.salesTotal);
					setResolution(res.resolution);
					setCustomerReferenceNumber(res.customerReferenceNumber);
					setPaymentBilledOn(res.paymentBilledOn);
					setCreatedDate(res.createdDate);
					setInstallationId(res.installationId);
					setInstallation(res.installation);
					setTicketUpdates(res.ticketUpdates);
					setCustomer(res.customer);
				});
		} catch (error) {}
	}, [api, params.ticketId, token]);

	useEffect(() => {
		if (!user || !token) {
			return;
		}
		fetch(api + "/tickets/messages/" + params.ticketId, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				userEmail: user.email,
			}),
			mode: "cors",
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				setMessages(res.messages);
			})
			.catch((error) => {});
	}, [api, params.ticketId, token, user, user.email]);

	useEffect(() => {
		if (!user || !token) {
			return;
		}
		fetch(api + "/tickets/replies/" + params.ticketId, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				userEmail: user.email,
			}),
			mode: "cors",
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				setReplies(res.replies);
			})
			.catch((error) => {});
	}, [api, params.ticketId, token, user, user.email]);

	const fetchFiles = useCallback(async () => {
		try {
			const response = await fetch(api + "/tickets/attachments/" + params.ticketId, { headers: { Authorization: "Bearer " + token } });
			if (!response.ok) {
				throw new Error("Failed to fetch files");
			}
			const data = await response.json();

			setFiles(data.attachments);
			setFile("");
		} catch (error) {}
	}, [api, params.ticketId, token]);

	const handleUpload = useCallback(
		async (e) => {
			e.preventDefault();

			if (!file) {
				return;
			}

			try {
				const formData = new FormData();
				formData.append("file", file);
				formData.append("installation", installation[1]);

				const s3Response = await fetch(api + "/tickets/upload/s3/" + params.ticketId, {
					method: "POST",
					headers: {
						Authorization: "Bearer " + token,
					},
					body: formData,
					mode: "cors",
				});
				if (!s3Response.ok) {
					throw new Error("Failed to upload file to s3");
				}
				const s3Data = await s3Response.json();

				const dropboxResponse = await fetch(api + "/tickets/upload/dropbox/" + params.ticketId, {
					method: "POST",
					headers: {
						Authorization: "Bearer " + token,
					},
					body: formData,
					mode: "cors",
				});
				if (!dropboxResponse.ok) {
					throw new Error("Failed to upload file to dropbox");
				}
				const dropboxData = await dropboxResponse.json();

				await fetchFiles();

				return { s3Response: s3Data, dropboxResponse: dropboxData };
			} catch (error) {}
		},
		[file, installation, api, params.ticketId, token, fetchFiles]
	);

	useEffect(() => {
		if (!isCommercial) {
			fetchFiles();
		}
	}, [fetchFiles, user.email, isCommercial]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (comment) {
			try {
				alert("Thank you. Your comment has been posted.");
				fetch(api + "/tickets/comment/" + params.ticketId, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						comment: comment,
						email: user.email,
						customer: customer ? customer[1] : "",
					}),
				})
					.then((response) => response.json())
					.then(() => window.location.reload());
			} catch (error) {}
		}
	};

	const handleNext = () => {
		const nextIndex = (currentIndex + 1) % tickets.length;
		const nextTicket = tickets[nextIndex];
		if (nextTicket) {
			setCurrentIndex(nextIndex);
			navigate(`/tickets/${nextTicket.id}`);
		}
	};

	const handlePrev = () => {
		const prevIndex = (currentIndex - 1 + tickets.length) % tickets.length;
		const prevTicket = tickets[prevIndex];
		if (prevTicket) {
			setCurrentIndex(prevIndex);
			navigate(`/tickets/${prevTicket.id}`);
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		const uploadedFile = e.dataTransfer.files[0];

		if (!allowedTypes.includes(uploadedFile.type)) {
			alert("Invalid file type. Please select a PDF, Word document, or image file.");
			setFile(null);
		} else {
			setFile(uploadedFile);
		}
	};

	const dateFormat = (inputDate, showTime = true) => {
		const date = new Date(inputDate.replace(" ", "T") + "Z");

		if (!isNaN(date.getTime())) {
			const options = {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				...(showTime && { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true }),
			};

			return date.toLocaleString("en-US", options);
		}
		return "";
	};

	const getFileName = (url) => {
		const fileNameStartsIndex = url.lastIndexOf("/") + 1;
		const fileNameEndsIndex = url.lastIndexOf("?") !== -1 ? url.lastIndexOf("?") : undefined;

		let fileName = url.substring(fileNameStartsIndex, fileNameEndsIndex);
		fileName = fileName.replace(/%/g, "").replace(/20/g, " ");

		return fileName;
	};

	return (
		<div
			className={`fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto ${
				user.email === "ps@1stle.com"
					? "bg-PSBackgroundImage"
					: user.email === "ess@1stle.com"
					? "bg-ESSBackgroundImage"
					: isCommercial
					? "bg-CommercialBackgroundImage"
					: "bg-1stleBackgroundImage"
			}`}>
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="flex items-center justify-center">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-5 bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 w-full md:w-[1200px] bg-opacity-95">
						{isCommercial ? (
							<div>
								{installationId && installationId !== -1 ? (
									<Link
										className="font-medium text-blue-600 hover:underline cursor-pointer"
										to="/tickets"
										state={{ id: 1, name: installationId }}>
										View All Tickets
									</Link>
								) : null}
							</div>
						) : (
							<div>
								<Link className="font-medium text-blue-600 hover:underline cursor-pointer" to="/tickets">
									View All Tickets
								</Link>
							</div>
						)}
						{tickets.length > 1 ? (
							<div className="flex items-center justify-between col-span-full">
								<ImArrowLeft2 size={30} className="cursor-pointer hover:text-blue-500" onClick={handlePrev} />
								<ImArrowRight2 size={30} className="cursor-pointer hover:text-blue-500" onClick={handleNext} />
							</div>
						) : null}
						<div className="flex items-center justify-center col-span-full">
							<span className="text-2xl md:text-5xl font-bold">Ticket Number : {params.ticketId}</span>
						</div>
						{isCommercial ? (
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full">
								<div
									className={
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] w-full p-1 mb-5"
											: user.email === "ess@1stle.com"
											? "bg-lime-400 w-full p-1 mb-5"
											: isCommercial
											? "bg-[#FFEA00] w-full p-1 mb-5"
											: "bg-blue-50 w-full p-1 mb-5"
									}>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-black">
										Site Info
									</h1>
								</div>
								{isLoading ? (
									<div className="flex items-center justify-center">
										<LoadingCircle />
									</div>
								) : (
									<div>
										<p className="mb-3 font-normal text-gray-700">Site: {installation ? installation : ""}</p>
										<p className="mb-3 font-normal text-gray-700">
											Address: {street && city && state && zip ? street + ", " + city + ", " + state + " " + zip : ""}
										</p>
									</div>
								)}
							</div>
						) : null}
						<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full">
							<div
								className={
									user.email === "ps@1stle.com"
										? "bg-[#FFAC1C] w-full p-1 mb-5"
										: user.email === "ess@1stle.com"
										? "bg-lime-400 w-full p-1 mb-5"
										: isCommercial
										? "bg-[#FFEA00] w-full p-1 mb-5"
										: "bg-blue-50 w-full p-1 mb-5"
								}>
								<h1
									className={`flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight ${
										isCommercial ? "text-black" : "text-gray-700"
									}`}>
									Overview
								</h1>
							</div>
							{isLoading ? (
								<div className="flex items-center justify-center">
									<LoadingCircle />
								</div>
							) : (
								<div>
									{!isCommercial ? (
										<p className="mb-3 font-normal text-gray-700">
											Address: {street && city && state && zip ? street + ", " + city + ", " + state + " " + zip : ""}
										</p>
									) : null}
									{!isCommercial ? (
										<p className="mb-3 font-normal text-gray-700">
											Customer Reference Number: {customerReferenceNumber ? customerReferenceNumber : ""}
										</p>
									) : null}
									<p className="mb-3 font-normal text-gray-700">Status: {status ? status[1] : ""}</p>
									<p className="mb-3 font-normal text-gray-700">Category: {category}</p>
									<p className="mb-3 font-normal text-gray-700">
										Scheduled Service Date: {scheduledDate !== false ? dateFormat(scheduledDate, false) : ""}
									</p>
									{paymentBilledOn !== false ? (
										<p className="mb-3 font-normal text-gray-700">
											Sales Order Total: {salesOrderTotal ? "$" + salesOrderTotal.toFixed(2) : ""}
										</p>
									) : null}
									<p className="mb-3 font-normal text-gray-700 whitespace-pre-line">Ticket Updates: {ticketUpdates ? ticketUpdates : ""}</p>
									{resolution !== false ? <p className="mb-3 font-normal text-gray-700">Resolution: {resolution ? resolution : ""}</p> : null}
									<p className="mb-3 font-normal text-gray-700">
										Created Date: {createdDate ? new Date(createdDate).toLocaleDateString() : ""}
									</p>
								</div>
							)}
						</div>
						{!isCommercial ? (
							<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full">
								<div className="bg-blue-50 w-full p-1 mb-5">
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Attachments
									</h1>
								</div>
								<div>
									<form onSubmit={handleUpload}>
										{isLoading ? (
											<div className="flex items-center justify-center mb-3">
												<LoadingCircle />
											</div>
										) : (
											<>
												{files && files.length > 0
													? files.map((file, index) => (
															<li className="list-none mb-3" key={index}>
																{index + 1 + "."}{" "}
																<a
																	href={file}
																	className="font-medium text-blue-600 hover:underline cursor-pointer"
																	target="_blank"
																	rel="noopener noreferrer">
																	{getFileName(file)}
																</a>
															</li>
													  ))
													: null}
											</>
										)}
										<div onDragOver={handleDragOver} onDrop={handleDrop} className="flex items-center justify-center mb-4">
											<label
												htmlFor="dropzone-file"
												className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
												<div className="flex flex-col items-center justify-center pt-5 pb-6">
													<svg
														className="w-8 h-8 mb-4 text-gray-500"
														aria-hidden="true"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 20 16">
														<path
															stroke="currentColor"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
														/>
													</svg>
													<p className="mb-2 text-sm text-gray-500">
														<span className="font-semibold">Click to upload</span> or drag and drop
													</p>
													<p className="text-xs text-gray-500">PDF, WORD, PNG, JPG or GIF</p>
												</div>
												<input
													id="dropzone-file"
													type="file"
													name="file"
													className="hidden"
													accept=".pdf,.docx,image/jpeg,image/png,image/gif"
													onChange={(e) => setFile(e.target.files[0])}
												/>
											</label>
										</div>
										{file && (
											<div className="flex items-center justify-center mb-4">
												<p>{file.name}</p>
											</div>
										)}
										<div className="flex items-center justify-center">
											<button
												className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 hover:bg-blue-600"
												type="submit">
												Upload
											</button>
										</div>
									</form>
								</div>
							</div>
						) : null}
						<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full">
							<div
								className={
									user.email === "ps@1stle.com"
										? "bg-[#FFAC1C] w-full p-1 mb-5"
										: user.email === "ess@1stle.com"
										? "bg-lime-400 w-full p-1 mb-5"
										: isCommercial
										? "bg-[#FFEA00] w-full p-1 mb-5"
										: "bg-blue-50 w-full p-1 mb-5"
								}>
								<h1
									className={`flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight ${
										isCommercial ? "text-black" : "text-gray-700"
									}`}>
									Comments
								</h1>
							</div>
							<div>
								<form onSubmit={handleSubmit}>
									<div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
										<label htmlFor="comment" className="sr-only">
											Your comment
										</label>
										<textarea
											id="comment"
											rows="6"
											className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
											placeholder="Write a comment..."
											onChange={(e) => setComment(e.target.value)}
											required></textarea>
									</div>
									<div className="flex items-center justify-center">
										<button
											type="submit"
											className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 hover:bg-blue-600">
											Post
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className={"p-6 bg-white border border-gray-200 rounded-lg shadow-xl col-span-full"}>
							<div
								className={
									user.email === "ps@1stle.com"
										? "bg-[#FFAC1C] w-full p-1 mb-5"
										: user.email === "ess@1stle.com"
										? "bg-lime-400 w-full p-1 mb-5"
										: isCommercial
										? "bg-[#FFEA00] w-full p-1 mb-5"
										: "bg-blue-50 w-full p-1 mb-5"
								}>
								<h1
									className={`flex items-center justify-center mb-2 mt-2 text-lg sm:text-xl font-bold tracking-tight ${
										isCommercial ? "text-black" : "text-gray-700"
									}`}>
									Messages
								</h1>
							</div>
							{isLoading ? (
								<div className="flex items-center justify-center">
									<LoadingCircle />
								</div>
							) : (
								<div>
									{sortedMessages && sortedMessages.length > 0
										? sortedMessages.map((message, i) => (
												<li className="list-none" key={i}>
													<div className={`pt-2 px-2 rounded-lg ${message.type === "message" ? "bg-blue-100" : "bg-gray-100"}`}>
														<p className="font-normal text-gray-700 break-words">
															<span className="font-medium">{message.author_id ? message.author_id[1] : ""}</span>{" "}
															{message.type === "message" ? "commented" : "replied"}{" "}
															{message.body ? message.body.replace(/(<([^>]+)>)/gi, "") : ""}
														</p>
														<p className="mb-3 font-light text-gray-700">
															{message.create_date ? dateFormat(message.create_date) : ""}
														</p>
													</div>
												</li>
										  ))
										: null}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
