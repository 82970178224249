import { useEffect, useState } from "react";
import { BiSolidErrorAlt } from "react-icons/bi";
import { IoMdWarning } from "react-icons/io";

const removeAutonums = (s) => {
	const regex = /(-\s?\d+)+$/;
	return s.replace(regex, "");
};
const AlertIconList = ({ site }) => {
	const [redAlerts, setRedAlerts] = useState([]);
	const [yellowAlerts, setYellowAlerts] = useState([]);
	const goToAlertPage = () => {
		const baseUrl = window.location.origin;
		const fullUrl = `${baseUrl}/alerts?query=${encodeURIComponent(removeAutonums(site.name))}`;
		window.open(fullUrl, "_blank", "noopener,noreferrer");
	};

	useEffect(() => {
		if (!site || !site.alerts) {
			return;
		}
		let newRedAlerts = [];
		let newYellowAlerts = [];
		site.alerts.forEach((alert) => {
			if (alert === "Plausible Disconnect") {
				newRedAlerts.push("Plausible Disconnect");
			} else if (alert === "Low Generation") {
				newRedAlerts.push("Low Generation");
			} else if (alert === "Generation Warning") {
				newYellowAlerts.push("Generation Warning");
			} else if (alert === "Incorrect Readings") {
				newRedAlerts.push("Incorrect Readings");
			}
		});
		setRedAlerts(newRedAlerts);
		setYellowAlerts(newYellowAlerts);
	}, [site]);

	if (yellowAlerts.length === 0 && redAlerts.length === 0) {
		return (
			<div>
				<p>-</p>
			</div>
		);
	}

	return (
		<div className="flex gap-2 relative">
			{redAlerts.length > 0 ? (
				<div className="flex items-center cursor-pointer select-none group" onClick={goToAlertPage}>
					<BiSolidErrorAlt color="red" />
					<p>{redAlerts.length}</p>
					<div className="absolute top-full left-0 mt-2 hidden group-hover:block bg-white border border-black shadow-lg rounded-md p-2 z-[2000] max-w-[40vw] whitespace-nowrap">
						<div className="flex items-center mb-1 ">
							<BiSolidErrorAlt color="red" size={24} />
							<p className="font-bold text-lg ml-2">Issues</p>
						</div>
						{redAlerts.map((mapData) => (
							<div key={mapData} className="ml-2">
								{"- " + mapData}
							</div>
						))}
					</div>
				</div>
			) : null}
			{yellowAlerts.length > 0 ? (
				<div className="flex items-center cursor-pointer select-none group" onClick={goToAlertPage}>
					<IoMdWarning color="#EED202" />
					<p>{yellowAlerts.length}</p>
					<div className="absolute top-full left-0 mt-2 hidden group-hover:block bg-white border border-black shadow-lg rounded-md p-2 z-10">
						<div className="flex items-center mb-1">
							<IoMdWarning color="#EED202" size={24} />
							<p className="font-bold text-lg ml-2">Warnings</p>
						</div>
						{yellowAlerts.map((mapData) => (
							<div key={mapData} className="ml-2">
								{"- " + mapData}
							</div>
						))}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default AlertIconList;
