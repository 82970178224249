import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Checkout } from "./Checkout";
import { IoArrowBackCircle } from "react-icons/io5";

export const ServiceOrder = ({ api, sidebarOpen, token }) => {
    const params = useParams();
    const navigate = useNavigate();
    
    const initialOptions = {
		"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
		currency: "USD",
		intent: "capture",
	};

    return (
        <div className="bg-1stleBackgroundImage fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto">
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="flex items-center justify-center">
					<div className="bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
                        <div className="flex flex-col items-center justify-center mb-14">
                            <div
                                className="absolute top-5 left-5 p-3 flex items-center justify-center gap-2 w-32 cursor-pointer bg-slate-300 hover:bg-slate-400"
                                onClick={() => navigate(-1)}>
                                <IoArrowBackCircle className="text-2xl cursor-pointer" />
                                <p className="cursor-pointer">Go back</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
							<span className="text-2xl md:text-5xl font-bold">Service Checkout</span>
						</div>
                        {params.service === "panel-cleaning" ? (
                            <div className="flex flex-col md:flex-row items-center justify-center mt-10 gap-10">
                                <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-xl">
                                    <img
                                        className="w-80 h-40 cursor-pointer transform transition duration-500 hover:scale-105"
                                        src={process.env.REACT_APP_PANEL_CLEANING_IMAGE_URL}
                                        alt="Panel Cleaning"
                                    />
                                    <h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
                                        Panel Cleaning
                                    </h1>
                                    <div>
                                        <p className="text-sm md:text-base mb-3 font-normal text-gray-700">Duration: 4 Hr</p>
                                        <p className="text-sm md:text-base mb-3 font-normal text-gray-700">Fee: $350.00 each</p>
                                        <p className="text-sm md:text-base mb-3 font-normal text-gray-700">
                                            ***Site visit fee's non-refundable after 24 <br /> hours of payment, except for <br /> Workmanship related
                                            Issue's***
                                        </p>
                                    </div>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <Checkout api={api} token={token} service={params.service} />
                                    </PayPalScriptProvider>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col md:flex-row items-center justify-center mt-10 gap-10">
                                <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-xl">
                                    <img
                                        className="w-80 h-40 cursor-pointer transform transition duration-500 hover:scale-105"
                                        src={process.env.REACT_APP_SERVICE_IMAGE_URL}
                                        alt="Panel Cleaning"
                                    />
                                    <h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
                                        Service Visit
                                    </h1>
                                    <div>
                                        <p className="text-sm md:text-base mb-3 font-normal text-gray-700">Duration: 2 Hr</p>
                                        <p className="text-sm md:text-base mb-3 font-normal text-gray-700">Fee: $250.00 each</p>
                                        <p className="text-sm md:text-base mb-3 font-normal text-gray-700">
                                            ***Site visit fee's non-refundable after 24 <br /> hours of payment, except for <br /> Workmanship related
                                            Issue's***
                                        </p>
                                    </div>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <Checkout api={api} token={token} service={params.service} />
                                    </PayPalScriptProvider>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
