import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/Utility/PageLoader";
import { Route, Routes, Navigate } from "react-router-dom";
import { HomePage } from "./components/HomePage";
import { Installation } from "./components/Installation";
import { NavBar } from "./components/Navigation/NavBar";
import { Services } from "./components/Services/Services";
import { Contact } from "./components/Contact/Contact";
import { CreditCardForm } from "./components/Resources/CreditCardForm";
import { TransferOwnershipForm } from "./components/Resources/TransferOwnershipForm";
import { About1LE } from "./components/Resources/About1LE";
import { Tickets } from "./components/System Support/Tickets";
import { SubmitTicket } from "./components/System Support/SubmitTicket";
import { ViewTicket } from "./components/System Support/ViewTicket";
import { ViewInstallation } from "./components/ViewInstallation";
import MonitoringDashboard from "./components/Monitoring/MonitoringDashboard";
import Monitoring from "./components/Monitoring/Monitoring";
import { ServiceOrder } from "./components/Services/ServiceOrder";
import { ServiceAddress } from "./components/Services/ServiceAddress";
import APIDocs from "./components/Resources/APIDocs";
import { jwtDecode } from "jwt-decode";
import Alerts from "./components/Monitoring/Alerts/Alerts";

function App() {
	const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const [token, setToken] = useState("");
	const [isCommercial, setIsCommercial] = useState(false);

	let api = process.env.REACT_APP_API_URL;

	useEffect(() => {
		if (!isLoading && user) {
			getAccessTokenSilently().then((newToken) => {
				setToken(newToken);

				const decodedToken = jwtDecode(newToken);
				const userRoles = decodedToken[process.env.REACT_APP_AUTH0_AUDIENCE + "roles"] || [];
				setIsCommercial(userRoles.includes("Commercial"));
			});
		}
	}, [getAccessTokenSilently, isLoading, user]);

	if (isLoading) {
		return (
			<div>
				<PageLoader />
			</div>
		);
	}

	return (
		<div>
			{isAuthenticated && <NavBar api={api} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} token={token} isCommercial={isCommercial} />}
			<Routes>
				<Route path="/" element={isAuthenticated ? <Navigate to="/monitoring" /> : <HomePage />} />
				<Route
					path="/installation"
					element={isAuthenticated ? <Installation api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>
				<Route
					path="/installation/search"
					element={isAuthenticated ? <Installation api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>
				{/* <Route path="/api" element={<APIDocs api={api} sidebarOpen={sidebarOpen} token={token} />} /> */}
				<Route
					path="/services"
					element={isAuthenticated ? <Services api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>
				<Route
					path="/services/search"
					element={isAuthenticated ? <Services api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>
				<Route
					path="/alerts"
					element={isAuthenticated ? <Alerts api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>
				<Route
					path="/monitoring"
					element={
						isAuthenticated ? <MonitoringDashboard api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />
					}
				/>
				<Route
					path="/monitoring/:id"
					element={isAuthenticated ? <Monitoring api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>

				<Route
					path="/installation/:installationId"
					element={
						isAuthenticated ? <ViewInstallation api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />
					}
				/>
				<Route
					path="/tickets"
					element={isAuthenticated ? <Tickets api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>
				<Route
					path="/tickets/:ticketId"
					element={isAuthenticated ? <ViewTicket api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>
				<Route
					path="/submitticket/:installationId"
					element={isAuthenticated ? <SubmitTicket api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} /> : <HomePage />}
				/>

				<Route
					path="/services/select-address"
					element={isAuthenticated && !isCommercial ? <ServiceAddress api={api} sidebarOpen={sidebarOpen} token={token} /> : <HomePage />}
				/>
				<Route
					path="/services/:service"
					element={isAuthenticated && !isCommercial ? <ServiceOrder api={api} sidebarOpen={sidebarOpen} token={token} /> : <HomePage />}
				/>

				<Route
					path="/submitticket"
					element={
						isAuthenticated && !isCommercial ? (
							<SubmitTicket api={api} sidebarOpen={sidebarOpen} token={token} isCommercial={isCommercial} />
						) : (
							<HomePage />
						)
					}
				/>

				<Route path="/about" element={isAuthenticated && !isCommercial ? <About1LE sidebarOpen={sidebarOpen} /> : <HomePage />} />
				<Route path="/creditcardform" element={isAuthenticated && !isCommercial ? <CreditCardForm sidebarOpen={sidebarOpen} /> : <HomePage />} />
				<Route
					path="/transferownershipform"
					element={isAuthenticated && !isCommercial ? <TransferOwnershipForm sidebarOpen={sidebarOpen} /> : <HomePage />}
				/>
				<Route
					path="/contact"
					element={isAuthenticated && !isCommercial ? <Contact api={api} sidebarOpen={sidebarOpen} token={token} /> : <HomePage />}
				/>
			</Routes>
		</div>
	);
}

export default App;
