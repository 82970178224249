import React from "react";
import { useState } from "react";
import { AddressAutofill } from "@mapbox/search-js-react";

export const Contact = ({ api, sidebarOpen, token }) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");
	const [reason, setReason] = useState("");
	const [productService, setProductService] = useState("");
	const [message, setMessage] = useState("");
	const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [addressError, setAddressError] = useState(false);
	const [cityError, setCityError] = useState(false);
	const [stateError, setStateError] = useState(false);
	const [zipError, setZipError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [reasonError, setReasonError] = useState(false);
	const [productServiceError, setProductServiceError] = useState(false);
	const [messageError, setMessageError] = useState(false);

	const submitForm = (e) => {
		if (firstName && lastName && email && address && city && state && zip && phone && reason && productService && message) {
			try {
				alert("Thank you. We'll respond to you as soon as possible.");
				fetch(api + "/contact", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token,
					},
					body: JSON.stringify({
						firstName: firstName,
						lastName: lastName,
						email: email,
						address: address,
						city: city,
						state: state,
						zip: zip,
						phone: phone,
						reason: reason,
						productService: productService,
						message: message,
					}),
				}).then((response) => response.json());
			} catch (error) {}
		} else {
			e.preventDefault();
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});

			if (!firstName) setFirstNameError(true);
			if (!lastName) setLastNameError(true);
			if (!email) setEmailError(true);
			if (!address) setAddressError(true);
			if (!city) setCityError(true);
			if (!state) setStateError(true);
			if (!zip) setZipError(true);
			if (!phone) setPhoneError(true);
			if (!reason) setReasonError(true);
			if (!productService) setProductServiceError(true);
			if (!message) setMessageError(true);
		}
	};

	const formatPhone = (value) => {
		if (!value) return value;

		const phoneNumber = value.replace(/[^\d]/g, "");
		const phoneNumberLength = phoneNumber.length;

		if (phoneNumberLength < 4) return phoneNumber;

		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
		}

		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
	};

	const validatePhone = () => {
		var phoneValidation = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

		if (phone.match(phoneValidation)) {
			setPhoneErrorMessage("");
		} else {
			setPhoneErrorMessage("Please enter a valid 10 digit phone number. Example of acceptable format: (123) 456-7890.");
		}
	};

	return (
		<div
			className="bg-1stleBackgroundImage fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto">
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="flex items-center justify-center">
					<form
						onSubmit={submitForm}
						noValidate
						className="bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 bg-opacity-95">
						<div className="flex items-center justify-center">
							<span className="text-2xl md:text-5xl font-bold">Contact Us</span>
						</div>
						<div className="flex items-center justify-center p-5 mb-10">
							<span className="text-lg md:text-2xl">Got a question? Send us a message and we'll respond as soon as possible</span>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="firstName">
									First Name
								</label>
								<input
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
										firstNameError ? "border-red-500" : ""
									}`}
									id="firstName"
									type="text"
									placeholder="First Name"
									onChange={(e) => setFirstName(e.target.value)}
									required
								/>
							</div>
							<div className="w-full md:w-1/2 px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="lastName">
									Last Name
								</label>
								<input
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
										lastNameError ? "border-red-500" : ""
									}`}
									id="lastName"
									type="text"
									placeholder="Last Name"
									onChange={(e) => setLastName(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
									Email
								</label>
								<input
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
										emailError ? "border-red-500" : ""
									}`}
									id="email"
									type="email"
									placeholder="Email"
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="address">
									Address
								</label>
								<AddressAutofill accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}>
									<input
										className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
											addressError ? "border-red-500" : ""
										}`}
										id="address"
										type="text"
										placeholder="Address"
										autoComplete="address-line1"
										onChange={(e) => setAddress(e.target.value)}
										required
									/>
								</AddressAutofill>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="city">
									City
								</label>
								<input
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
										cityError ? "border-red-500" : ""
									}`}
									id="city"
									type="text"
									placeholder="City"
									autoComplete="address-level2"
									onChange={(e) => setCity(e.target.value)}
									required
								/>
							</div>
							<div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="state">
									State
								</label>
								<input
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
										stateError ? "border-red-500" : ""
									}`}
									id="state"
									type="text"
									placeholder="State"
									autoComplete="address-level1"
									onChange={(e) => setState(e.target.value)}
									required
								/>
							</div>
							<div className="w-full md:w-1/4 px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="zip">
									Zip
								</label>
								<input
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
										zipError ? "border-red-500" : ""
									}`}
									id="zip"
									type="text"
									placeholder="Zip"
									autoComplete="postal-code"
									onChange={(e) => setZip(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="phone">
									Phone Number
								</label>
								<input
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
										phoneError ? "border-red-500" : ""
									}`}
									id="phone"
									type="tel"
									placeholder="(###) ### - ####"
									onChange={(e) => setPhone(formatPhone(e.target.value))}
									onBlur={validatePhone}
									value={phone}
									required
								/>
								<span className="text-sm text-red-500">{phoneErrorMessage}</span>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="reason">
									Reason
								</label>
								<select
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
										reasonError ? "border-red-500" : ""
									}`}
									id="reason"
									defaultValue={"Default"}
									onChange={(e) => setReason(e.target.value)}
									required>
									<option value="Default" disabled>
										Choose reason...
									</option>
									<option value="Requesting Quote">Requesting Quote</option>
									<option value="Product/Service Enquiry">Product/Service Enquiry</option>
									<option value="Other">Other</option>
								</select>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="product/service">
									Product or Service
								</label>
								<select
									className={`appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="product/service ${
										productServiceError ? "border-red-500" : ""
									}`}
									defaultValue={"Default"}
									onChange={(e) => setProductService(e.target.value)}
									required>
									<option value="Default" disabled>
										Choose product or service
									</option>
									<option value="Panel Cleaning">Panel Cleaning</option>
									<option value="Service Visit">Service Visit</option>
								</select>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="message">
									Message
								</label>
								<textarea
									className={`no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded shadow-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none ${
										messageError ? "border-red-500" : ""
									}`}
									id="message"
									placeholder="Your message or question"
									onChange={(e) => setMessage(e.target.value)}
									required></textarea>
							</div>
						</div>
						<div className="flex justify-center items-center flex-wrap -mx-3 mb-6">
							<div>
								<button
									className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-blue-500 rounded focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-blue-600"
									type="submit">
									Submit
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
