import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";

export const HomePage = () => {
	const { loginWithRedirect } = useAuth0();
	const [showBanner, setShowBanner] = useState(true);

	const handleLogin = async () => {
		await loginWithRedirect({
			appState: {
				returnTo: "/monitoring",
			},
			authorizationParams: {
				prompt: "login",
			},
		});
	};

	return (
		<div>
			{showBanner ? (
				<div
					id="banner"
					tabindex="-1"
					className="hidden md:flex fixed z-50 gap-8 justify-between items-start text-center py-3 px-4 w-full bg-blue-200 border border-b border-blue-200 sm:items-center lg:py-4">
					<p className="text-sm text-black">
						The new and improved Customer Portal has arrived! If you are new, Welcome! <br /> If you are an existing customer, please resign up with
						the email you used in the old customer portal to ensure all information is linked to you. If you no longer have access to that email
						used previously, sign up with the new email and please contact our service department (servicedept@1stle.com) with your name, address,
						and any contractual documentation from 1st Light Energy; so that we can update it on our records and relink your details. <br /> This
						website is currently on the BETA version, we are in the process of making the website more efficient and user friendly.
					</p>
					<button
						data-collapse-toggle="banner"
						type="button"
						className="flex items-center text-black hover:bg-blue-400 rounded-lg text-sm p-1.5"
						onClick={() => setShowBanner(false)}>
						<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"></path>
						</svg>
					</button>
				</div>
			) : null}
			<div className="bg-1stleBackgroundImage h-screen bg-cover bg-no-repeat flex flex-col items-center justify-center space-y-10">
				<img src={process.env.REACT_APP_1STLE_LOGO_URL} alt="1ST LIGHT ENERGY" />
				<button
					className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 shadow-lg shadow-lime-500/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
					onClick={handleLogin}>
					Log In
				</button>
			</div>
		</div>
	);
};
