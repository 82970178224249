import React from "react";

export const GraphModal = ({ setShowRotateModal }) => {
    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-700 bg-opacity-50 z-[100]" onClick={() => setShowRotateModal(false)}>
            <div className="flex flex-col items-center justify-center bg-white p-4 m-2 rounded-lg" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-2xl font-bold mb-4">
                    Please Rotate Your Device
                </h2>
                <p className="text-lg mb-4">
                    For the best viewing experience, please rotate your device to landscape mode.
                </p>
                <button onClick={() => setShowRotateModal(false)} className="bg-blue-500 text-white px-4 py-2 rounded-lg">
                    Close
                </button>
            </div>
        </div>
    )
}