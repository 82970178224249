import { useEffect, useState } from "react";

export default function PercentBar({ percentage, barWidth }) {
	return (
		<div className="flex p-1 gap-1 items-center w-full">
			<div className="w-full h-full border border-black bg-white">
				<div
					className={`${barWidth >= 80 ? "bg-green-500" : barWidth >= 50 ? "bg-yellow-500" : "bg-red-600"} h-5 flex items-center`}
					style={{ width: `${barWidth}%` }}
					title={barWidth + "%"}>
					<div className="flex flex-col text-xs font-bold pl-1">
						<p>{!isNaN(percentage) ? `${Math.ceil(percentage)}%` : "0%"}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
