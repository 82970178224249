import { useEffect, useState } from "react";
import { BiSolidErrorAlt } from "react-icons/bi";
import { ImNewTab } from "react-icons/im";
import { IoMdWarning } from "react-icons/io";

const AlertList = ({ selectedButton, redAlerts, yellowAlerts, selectedOptions, searchText }) => {
	const [filteredAlerts, setFilteredAlerts] = useState([]);

	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};

	useEffect(() => {
		let alerts = [];

		if (selectedButton === "all") {
			alerts = [...redAlerts, ...yellowAlerts];
		} else if (selectedButton === "red") {
			alerts = [...redAlerts];
		} else if (selectedButton === "yellow") {
			alerts = [...yellowAlerts];
		}

		if (selectedOptions.length > 0) {
			alerts = alerts.filter((alert) => selectedOptions.includes(alert.label));
		} else {
			alerts = [];
		}
		if (searchText.trim() !== "") {
			alerts = alerts.filter((filterData) => filterData.site.name.toLowerCase().includes(searchText.toLowerCase().trim()));
		}
		setFilteredAlerts(alerts);
	}, [redAlerts, selectedButton, yellowAlerts, selectedOptions, searchText]);

	const goToMonitoringPage = (siteId) => {
		const baseUrl = window.location.origin;
		const fullUrl = `${baseUrl}/monitoring/${siteId}`;
		window.open(fullUrl, "_blank", "noopener,noreferrer");
	};

	return (
		<div className="p-4 flex flex-col gap-2">
			<p className="text-2xl font-bold">{`Results: ${filteredAlerts.length}`}</p>
			{!filteredAlerts || filteredAlerts.length === 0 ? (
				<div className="border border-black p-3 rounded-lg bg-white text-center">
					<p>No alerts found. Please check your filters.</p>
				</div>
			) : (
				<>
					{filteredAlerts.map((mapData) => {
						return (
							<div className="border border-black p-3 rounded-lg bg-white shadow-md">
								<div className="flex gap-1 items-center">
									{mapData.type === "issue" ? <BiSolidErrorAlt color="red" size={24} /> : <IoMdWarning color="#EED202" size={24} />}
									<p>{mapData.label}</p>
								</div>
								<div className="flex justify-between items-center">
									<p className="font-bold text-lg">{removeAutonums(mapData.site.name)}</p>
									<button
										className="bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-lg flex items-center gap-1"
										onClick={() => goToMonitoringPage(mapData.site.id)}>
										<ImNewTab />
										Monitoring
									</button>
								</div>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};

export default AlertList;
