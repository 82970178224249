import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { TbArrowsRight } from "react-icons/tb";

const InverterRow = ({ inverter }) => {
	const [showConversions, setShowConversions] = useState(false);
	const [dcVolts, setDcVolts] = useState([]);
	const [dcCurrents, setDcCurrents] = useState([]);
	const [dcPower, setDcPower] = useState([]);
	const [acVoltage, setAcVoltage] = useState([]);
	const [acCurrent, setAcCurrent] = useState([]);
	const [acF, setAcF] = useState(null);

	useEffect(() => {
		const uacKeys = Object.keys(inverter).filter((key) => key.startsWith("uAc") && !key.endsWith("Str") && inverter[key] !== 0);
		const iAcKeys = Object.keys(inverter).filter((key) => key.startsWith("iAc") && !key.endsWith("Str") && inverter[key] !== 0);
		const iPvKeys = Object.keys(inverter).filter((key) => key.startsWith("iPv") && !key.endsWith("Str"));
		const uPvKeys = Object.keys(inverter).filter((key) => key.startsWith("uPv") && !key.endsWith("Str"));
		const powKeys = Object.keys(inverter).filter((key) => /^pow\d+$/.test(key) && !key.endsWith("Str"));

		setDcVolts(uPvKeys);
		setDcCurrents(iPvKeys);
		setDcPower(powKeys);
		setAcVoltage(uacKeys);
		setAcCurrent(iAcKeys);
		setAcF(inverter["fac"]);
	}, [inverter]);

	return (
		<>
			<div>
				{inverter.state === 1 ? (
					<div className="flex items-center gap-1">
						<FaCircle color="green" />
						<p>online</p>
					</div>
				) : inverter.state === 2 ? (
					<div className="flex items-center gap-1">
						<FaCircle color="gray" />
						<p>offline</p>
					</div>
				) : inverter.state === 3 ? (
					<div className="flex items-center gap-1">
						<FaCircle color="red" />
						<p>alarm</p>
					</div>
				) : (
					<p>Unknown</p>
				)}
			</div>
			<div className="break-all">
				<p>{inverter.inverter_sn}</p>
			</div>
			<p>
				{`${inverter.pac.toFixed(1)} `}
				<span className="text-xs">{inverter.pacStr}</span>
			</p>
			<p>
				{inverter.eToday ? inverter.eToday.toFixed(1) : "-"}
				<span className="text-xs">{" kWh"}</span>
			</p>
			<div>
				<button className="p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-lg" onClick={() => setShowConversions((prev) => !prev)}>
					{showConversions ? "Hide" : "Show More"}
				</button>
			</div>
			{showConversions ? (
				<div className="col-span-5 overflow-x-scroll lg:overflow-x-visible">
					<div className="flex justify-center gap-4">
						<div className="flex lg:gap-12 rounded-lg border border-black p-2 bg-gray-100">
							<div>
								<p className="font-bold">DC</p>
								{dcVolts.map((mapData, index) => {
									if (index > inverter.dcInputType) {
										return null;
									}
									return <p key={mapData + index}>{index + 1}</p>;
								})}
							</div>
							<div>
								<p className="font-bold">Voltage</p>
								{dcVolts.map((mapData, index) => {
									if (index > inverter.dcInputType) {
										return null;
									}
									return (
										<p key={mapData + index}>
											{inverter[mapData].toFixed(1)}
											<span className="text-xs">{" " + inverter[mapData + "Str"]}</span>
										</p>
									);
								})}
							</div>
							<div>
								<p className="font-bold">Current</p>
								{dcCurrents.map((mapData, index) => {
									if (index > inverter.dcInputType) {
										return null;
									}
									return (
										<p key={mapData + index}>
											{inverter[mapData].toFixed(1)}
											<span className="text-xs">{" " + inverter[mapData + "Str"]}</span>
										</p>
									);
								})}
							</div>
							<div>
								<p className="font-bold">Power</p>
								{dcPower.map((mapData, index) => {
									if (index > inverter.dcInputType) {
										return null;
									}
									return (
										<p key={mapData + index}>
											{inverter[mapData].toFixed(1)}
											<span className="text-xs">{" " + inverter[mapData + "Str"]}</span>
										</p>
									);
								})}
							</div>
						</div>
						<div className="flex justif-center items-center text-2xl">
							<TbArrowsRight />
						</div>
						<div className="flex lg:gap-12 rounded-lg border border-black p-2 bg-gray-100">
							<div>
								<p className="font-bold">AC</p>
								{acVoltage.map((mapData, index) => {
									return <p key={mapData + index}>{index + 1}</p>;
								})}
							</div>
							<div>
								<p className="font-bold">Voltage</p>
								{acVoltage.map((mapData, index) => {
									return (
										<p key={mapData + index}>
											{inverter[mapData].toFixed(1)}
											<span className="text-xs">{" " + inverter[mapData + "Str"]}</span>
										</p>
									);
								})}
							</div>
							<div>
								<p className="font-bold">Current</p>
								{acCurrent.map((mapData, index) => {
									return (
										<p key={mapData + index}>
											{inverter[mapData].toFixed(1)}
											<span className="text-xs">{" " + inverter[mapData + "Str"]}</span>
										</p>
									);
								})}
							</div>
							<div>
								<p className="font-bold">F</p>
								{acVoltage.map((mapData, index) => {
									return (
										<p key={mapData + index}>
											{acF.toFixed(2)}
											<span className="text-sm">{" Hz"}</span>
										</p>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default InverterRow;
