import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const ServiceAddress = ({ api, sidebarOpen, token }) => {
    const { user } = useAuth0();
    const [installation, setInstallation] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
		if (api === "" || !token) {
			return;
		}

		try {
			fetch(api + "/installation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					userEmail: user.email,
				}),
				mode: "cors",
			})
				.then((res) => {
					return res.json();
				})
				.then((res) => {
					setInstallation(res.installation);
				});
		} catch (error) {}
	}, [api, token, user.email]);

    return (
		<div className="bg-1stleBackgroundImage fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto">
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="flex items-center justify-center">
					<div className="bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
                        <div className="flex items-center justify-center">
                            <span className="text-2xl md:text-5xl font-bold">Services</span>
                        </div>
                        <span className="flex items-center justify-center mt-10 text-xl md:text-3xl text-gray-700">Please select an address</span>
                        <div className="flex items-center justify-center mt-10 gap-10">
                            {installation.map((install, index) => (
                                <div className="w-auto max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-xl" key={index}>
                                    <p className="flex items-center justify-center text-xl text-gray-700">{install.x_studio_site_street}</p>
                                    <button
                                        onClick={() => navigate("/services", { state: { id: 1, name: install.x_studio_site_street } })}
                                        className="bg-blue-500 hover:bg-blue-600 text-white w-full h-10 mt-3">
                                        Select
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}