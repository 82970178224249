import { Fragment } from "react";
import InverterRow from "./InverterRow";

const InvertersGrid = ({ inverters }) => {
	return (
		<>
			<div className="flex flex-col justify-center items-center mt-4 bg-white p-4 rounded-lg border border-black">
				<p className="text-2xl font-bold ">Inverters Overview</p>
			</div>
			<div className="mt-4 rounded-lg border border-black  bg-black">
				<p className="bg-black rounded-t-lg p-4 text-white text-center text-lg font-bold">Latest Information</p>
				<div className="grid grid-cols-5 gap-3 justify-center items-center rounded-b-lg bg-white p-4">
					<p className="font-bold">State</p>
					<p className="font-bold">Inverter SN</p>
					<p className="font-bold">Current Power</p>
					<p className="font-bold">Yesterday Yield</p>
					<p className="font-bold"></p>
					{inverters.map((mapData) => (
						<Fragment key={mapData.inverter_sn}>
							<InverterRow inverter={mapData} />
						</Fragment>
					))}
				</div>
			</div>
		</>
	);
};

export default InvertersGrid;
