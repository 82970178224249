import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import LoadingCircle from "../Utility/LoadingCircle";

export const Tickets = ({ api, sidebarOpen, token, isCommercial }) => {
	const { user } = useAuth0();
	const [tickets, setTickets] = useState([]);
	const [selectedStageId, setSelectedStageId] = useState([]);
	const [selectedIssueTypes, setSelectedIssueTypes] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { state } = useLocation();
	const projectId = state && state.name;
	const navigate = useNavigate();

	const filteredStages = Array.from(new Set(tickets.map(ticket => ticket.stage_id ? ticket.stage_id[1] : null))).filter(Boolean);
	const filteredIssues = Array.from(new Set(tickets.map(ticket => ticket.x_studio_issue_type || "Pending")));

	useEffect(() => {
		const controller = new AbortController();

		setIsLoading(true);
		fetch(api + "/tickets", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				userEmail: user.email,
				projectId: projectId,
				isCommercial: isCommercial,
			}),
			mode: "cors",
			signal: controller.signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				setIsLoading(false);
				setTickets(res.tickets);
			})
			.catch((error) => {});

		return () => controller.abort();
	}, [api, projectId, user.email, token, isCommercial]);

	const handleFilter = (e) => {
		e.stopPropagation();
		setShowFilter(!showFilter);
	}

	const handleCheckboxChange = (id, type) => {
		if (type === "status") {
			if (selectedStageId.includes(id)) {
				setSelectedStageId(prevState => prevState.filter(stageId => stageId !== id))
			}
			else {
				setSelectedStageId(prevState => [...prevState, id])
			}
		}
		else if (type === "issue") {
			if (selectedIssueTypes.includes(id)) {
				setSelectedIssueTypes(prevState => prevState.filter(issueType => issueType !== id));
			}
			else {
				setSelectedIssueTypes(prevState => [...prevState, id]);
			}
		}
	}

	const filteredTickets = tickets.filter(ticket => {
		const stageMatches = selectedStageId.length > 0 ? ticket.stage_id && selectedStageId.includes(ticket.stage_id[1]) : true;
		const issueMatches = selectedIssueTypes.length > 0 ? (ticket.x_studio_issue_type && selectedIssueTypes.includes(ticket.x_studio_issue_type)) || (!ticket.x_studio_issue_type && selectedIssueTypes.includes("Pending")) : true;
		return stageMatches && issueMatches;
	})
	
	return (
		<div
			className={`fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto ${
				user.email === "ps@1stle.com" 
					? "bg-PSBackgroundImage" 
					: user.email === "ess@1stle.com" 
						? "bg-ESSBackgroundImage"
						: isCommercial
							? "bg-CommercialBackgroundImage"
							: "bg-1stleBackgroundImage"
			}`}
			onClick={() => setShowFilter(false)}>
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="flex items-center justify-center">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-10 bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
						{isCommercial ? (
							<div className="md:col-span-1 col-span-full mb-5">
								<div
									className={`absolute top-5 left-5 p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com" 
												? "bg-lime-400 hover:bg-lime-500"
												: "bg-[#FFEA00] hover:bg-yellow-400"
									}`}
									onClick={() => navigate("/installation")}>
									<IoArrowBackCircle className="text-2xl cursor-pointer" />
									<p className="cursor-pointer">Go back</p>
								</div>
								<div
									className={`absolute top-5 right-5 p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com" 
												? "bg-lime-400 hover:bg-lime-500"
												: "bg-[#FFEA00] hover:bg-yellow-400"
									}`}
									onClick={() => navigate("/submitticket/" + projectId, { state: { id: 1, name: projectId } })}>
									<p className="cursor-pointer">Create Ticket</p>
								</div>
							</div>
						) : null}
						<div className="flex items-center justify-center col-span-full">
							<span className="font-bold text-2xl md:text-5xl">Tickets</span>
						</div>
						{isLoading ? (
							<div className="flex items-center justify-center col-span-full">
								<LoadingCircle />
							</div>
						) : tickets.length > 0 ? (
							<>
								{isCommercial ? (
									<div className="absolute top-1/2 right-10 hidden md:block">
										<button
											id="dropdownButton"
											type="button"
											data-dropdown-toggle="dropdown"
											onClick={handleFilter}
											className={`text-black focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-4 py-2.5 text-center inline-flex items-center ${
												user.email === "ps@1stle.com"
													? "bg-[#FFAC1C] hover:bg-orange-500"
													: user.email === "ess@1stle.com" 
														? "bg-lime-400 hover:bg-lime-500"
														: "bg-[#FFEA00] hover:bg-yellow-400"
											}`}>
												<FaFilter className="mr-2" size={15} /> Filter 
												<svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
													<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
												</svg>
										</button>
							
										{showFilter && (
											<div id="dropdown" className="absolute left-1/2	transform -translate-x-1/2 z-10 w-40 p-3 mt-1 bg-white rounded-lg shadow" onClick={(e) => e.stopPropagation()}>
												<h6 className="mb-3 text-sm font-bold text-gray-900">
													Status
												</h6>
												<ul className="space-y-2 text-sm" aria-labelledby="status">
													{filteredStages.map((stage, i) => (
														<li className="flex items-center" key={i}>
															<input 
																id={`status-${i}`} 
																type="checkbox"
																checked={selectedStageId.includes(stage)}
																value=""
																onChange={() => handleCheckboxChange(stage, "status")}
																className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2" 
															/>
															<label htmlFor={`status-${i}`} className="ml-2 text-sm font-medium text-gray-900">
																{stage} 
															</label>
														</li>
													))}
												</ul>
												<h6 className="mt-3 mb-3 text-sm font-bold text-gray-900">
													Issue Type
												</h6>
												<ul className="space-y-2 text-sm" aria-labelledby="issue">
													{filteredIssues.map((issue, i) => (
														<li className="flex items-center" key={i}>
															<input 
																id={`issue-${i}`} 
																type="checkbox"
																checked={selectedIssueTypes.includes(issue)}
																value=""
																onChange={() => handleCheckboxChange(issue, "issue")}
																className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2" 
															/>
															<label htmlFor={`issue-${i}`} className="ml-2 text-sm font-medium text-gray-900">
																{issue} 
															</label>
														</li>
													))}
												</ul>
											</div>
										)}
									</div>
								) : null}
								{filteredTickets.map((ticket, i) => (
									<li className="list-none" key={i}>
										<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-xl w-full h-full">
											<div
												className={
													user.email === "ps@1stle.com"
														? "bg-[#FFAC1C] w-full p-1 mb-5"
														: user.email === "ess@1stle.com"
															? "bg-lime-400 w-full p-1 mb-5"
															: isCommercial
																? "bg-[#FFEA00] w-full p-1 mb-5"
																: "bg-blue-50 w-full p-1 mb-5"
												}>
												<h1
													className={`flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight ${
														isCommercial ? "text-black" : "text-gray-700"
													}`}>
													{ticket.x_studio_issue_type ? ticket.x_studio_issue_type : "Pending"}
												</h1>
											</div>
											<div>
												<p className="mb-3 font-normal text-gray-700">
													Ticket Number:{" "}
													<Link
														className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
														to={"/tickets/" + ticket.id}>
														{ticket.id}
													</Link>
												</p>
												<p className="mb-3 font-normal text-gray-700">Status: {ticket.stage_id ? ticket.stage_id[1] : ""}</p>
												<p className="mb-3 font-normal text-gray-700">Created Date: {ticket.create_date ? new Date(ticket.create_date).toLocaleDateString() : ""}</p>
											</div>
										</div>
									</li>
								))}
							</>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};
